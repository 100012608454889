import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout/layout"
import ImageTop from "../components/ImageTop/ImageTop"



const HotelPage = () => {
  const data = useStaticQuery(query)
  data.strapiHotel.description = data.strapiHotel.description.split(`img src="/upload`).join(`img src="${process.env.GATSBY_API_URL}/upload`);
  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div>
        <ImageTop
          image={ data.strapiHotel.image }
          sizes='100vw'
          title={data.strapiHotel.title}
        />
        <div className="uk-section">
          <div className="uk-container uk-container">
            <div className="ck-content" dangerouslySetInnerHTML={{ __html: data.strapiHotel.description }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const query = graphql`
    query {
        strapiHotel{
            title
            description
            image{
                localFile{
                    childImageSharp {
                        fixed(width: 1800, height: 1200) {
                            src
                        }
                    }
                }
            }
        }
        strapiHomepage {
            seo {
                metaTitle
                metaDescription
            }
        }
    }
`

export default HotelPage